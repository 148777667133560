<template>
  <div :class="g_darkmode ? '' : 'grey lighten-5'">
    <v-container class="minvh" style="position: relative">
      <h3>{{ $t("userLogin.title") }}</h3>

      <v-divider class="my-3"></v-divider>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          :class="{ 'mb-2': $vuetify.breakpoint.xsOnly }"
        >
          <v-card :class="!g_darkmode && 'white'" flat>
            <v-card-title class="body-1">{{
              $t("userLogin.student")
            }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mr-2">
              <v-switch
                v-model="valueStudent"
                @change="switchHandler('student')"
              >
                <template v-slot:label>
                  <span class="ml-2">{{ valueStudent ? "ON" : "OFF" }}</span>
                  <v-progress-circular
                    v-show="loadingStudent"
                    :indeterminate="loadingStudent"
                    :value="0"
                    size="24"
                    class="ml-2"
                  ></v-progress-circular>
                </template>
              </v-switch>
              <v-divider class="my-2"></v-divider>
              <span class="error--text">{{
                $t("userLogin.student_hint")
              }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :class="!g_darkmode && 'white'" flat>
            <v-card-title class="body-1">{{
              $t("userLogin.teacher")
            }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mr-2">
              <v-switch
                v-model="valueTeacher"
                @change="switchHandler('teacher')"
              >
                <template v-slot:label>
                  <span class="ml-2">{{ valueTeacher ? "ON" : "OFF" }}</span>
                  <v-progress-circular
                    v-show="loadingTeacher"
                    :indeterminate="loadingTeacher"
                    :value="0"
                    size="24"
                    class="ml-2"
                  ></v-progress-circular>
                </template>
              </v-switch>
              <v-divider class="my-2"></v-divider>
              <span class="error--text">{{
                $t("userLogin.teacher_hint")
              }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-alert class="mt-4" type="warning" dismissible border="left">
            <div>
              *{{ $t("userLogin.attention") }}:
              <ul>
                <li>{{ $t("userLogin.attention_1") }}</li>
                <li>{{ $t("userLogin.attention_2") }}</li>
              </ul>
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getNisDefaultUsername,
  setNisDefaultUsername
} from "@/api/admin/settings/user";

export default {
  computed: mapGetters(["g_darkmode"]),
  data() {
    return {
      valueStudent: false,
      valueTeacher: false,
      loadingStudent: false,
      loadingTeacher: false
    };
  },
  mounted() {
    this.$Progress.start();
    getNisDefaultUsername().then(res => {
      if (res.data.code) {
        const data = res.data.data;
        this.valueStudent = data.nis_default_student.value == 1 ? true : false;
        this.valueTeacher = data.nis_default_employee.value == 1 ? true : false;
      }
      this.$Progress.finish();
    });
  },
  methods: {
    switchHandler(param) {
      switch (param) {
        case "student": {
          const value = this.valueStudent ? 1 : 0;
          this.loadingStudent = true;
          setNisDefaultUsername({ value: value, type: "STUDENT" }).then(res => {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: res.data.code ? "success" : "error"
            });
            this.loadingStudent = false;
          });
          break;
        }
        default: {
          const value = this.valueTeacher ? 1 : 0;
          this.loadingTeacher = true;
          setNisDefaultUsername({ value: value, type: "EMPLOYEE" }).then(
            res => {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: res.data.code ? "success" : "error"
              });
              this.loadingTeacher = false;
            }
          );
          break;
        }
      }
    }
  }
};
</script>
